import { useState } from "react";
import Mac from "./Mac";
import { Portfolio } from "./Portfolio";
import Background from "./Portfolio/Background";
import "./styles.css";

export default function App() {
  const [showBg, setShowBg] = useState(false);

  return (
    <div className="wrapper">
      {showBg ? (
        <div className="background">
          <Background />
        </div>
      ) : null}

      <Portfolio setShowBg={setShowBg} showBg={showBg} />
    </div>
  );
}
