import React, { useRef, useState, useEffect } from "react";
import * as THREE from "three";

const Background = () => {
  const mountRef = useRef(null);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  useEffect(() => {
    let camera, scene, renderer, parameters;
    let windowHalfX = window.innerWidth / 2;
    let windowHalfY = window.innerHeight / 2;
    const materials = [];

    const init = () => {
      camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        2000
      );
      camera.position.z = 1000;

      scene = new THREE.Scene();
      scene.fog = new THREE.FogExp2(0x000000, 0.0008);

      const geometry = new THREE.BufferGeometry();
      const vertices = [];

      const textureLoader = new THREE.TextureLoader();

      const assignSRGB = (texture) => {
        texture.colorSpace = THREE.SRGBColorSpace;
      };

      const sprite1 = textureLoader.load(
        "https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/sprites/snowflake1.png",
        assignSRGB
      );
      const sprite2 = textureLoader.load(
        "https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/sprites/snowflake2.png",
        assignSRGB
      );
      const sprite3 = textureLoader.load(
        "https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/sprites/snowflake3.png",
        assignSRGB
      );
      const sprite4 = textureLoader.load(
        "https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/sprites/snowflake4.png",
        assignSRGB
      );
      const sprite5 = textureLoader.load(
        "https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/sprites/snowflake5.png",
        assignSRGB
      );

      for (let i = 0; i < 10000; i++) {
        const x = Math.random() * 2000 - 1000;
        const y = Math.random() * 2000 - 1000;
        const z = Math.random() * 2000 - 1000;
        vertices.push(x, y, z);
      }

      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(vertices, 3)
      );

      parameters = [
        [[1.0, 0.2, 0.5], sprite2, 20],
        [[0.95, 0.1, 0.5], sprite3, 15],
        [[0.9, 0.05, 0.5], sprite1, 10],
        [[0.85, 0, 0.5], sprite5, 8],
        [[0.8, 0, 0.5], sprite4, 5],
      ];

      for (let i = 0; i < parameters.length; i++) {
        const color = parameters[i][0];
        const sprite = parameters[i][1];
        const size = parameters[i][2];

        materials[i] = new THREE.PointsMaterial({
          size: size,
          map: sprite,
          blending: THREE.AdditiveBlending,
          depthTest: false,
          transparent: true,
        });
        materials[i].color.setHSL(
          color[0],
          color[1],
          color[2],
          THREE.SRGBColorSpace
        );

        const particles = new THREE.Points(geometry, materials[i]);

        particles.rotation.x = Math.random() * 6;
        particles.rotation.y = Math.random() * 6;
        particles.rotation.z = Math.random() * 6;

        scene.add(particles);
      }

      renderer = new THREE.WebGLRenderer();
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      mountRef.current.appendChild(renderer.domElement);

      document.body.style.touchAction = "none";
      document.body.addEventListener("pointermove", onPointerMove);

      window.addEventListener("resize", onWindowResize);

      const gradientCanvas = document.createElement("canvas");
      gradientCanvas.width = 256;
      gradientCanvas.height = 256;

      const gradientCtx = gradientCanvas.getContext("2d");
      const gradient = gradientCtx.createLinearGradient(0, 0, 0, 256);
      gradient.addColorStop(0, "#1e4877"); // Dark blue
      gradient.addColorStop(0.6, "#4584b4"); // Light blue
      gradient.addColorStop(1, "#8fb3d9"); // Lightest blue

      gradientCtx.fillStyle = gradient;
      gradientCtx.fillRect(0, 0, 256, 256);

      const gradientTexture = new THREE.CanvasTexture(gradientCanvas);
      scene.background = gradientTexture;

      animate();
    };

    const onWindowResize = () => {
      windowHalfX = window.innerWidth / 2;
      windowHalfY = window.innerHeight / 2;

      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    const onPointerMove = (event) => {
      if (event.isPrimary === false) return;

      setMouseX(event.clientX - windowHalfX);
      setMouseY(event.clientY - windowHalfY);
    };

    const animate = () => {
      requestAnimationFrame(animate);

      render();
    };

    const render = () => {
      const time = Date.now() * 0.00002;

      camera.position.x += (mouseX - camera.position.x) * 0.01;
      camera.position.y += (-mouseY - camera.position.y) * 0.01;

      camera.lookAt(scene.position);

      for (let i = 0; i < scene.children.length; i++) {
        const object = scene.children[i];

        if (object instanceof THREE.Points) {
          object.rotation.y = time * (i < 4 ? i + 1 : -(i + 1));
        }
      }

      for (let i = 0; i < materials.length; i++) {
        const color = parameters[i][0];
        const h = ((360 * (color[0] + time)) % 360) / 360;
        materials[i].color.setHSL(h, color[1], color[2], THREE.SRGBColorSpace);
      }

      renderer.render(scene, camera);
    };

    init();

    return () => {
      if (mountRef.current) {
        mountRef.current?.removeChild(renderer.domElement);
      }
      window.removeEventListener("resize", onWindowResize);
      document.body.removeEventListener("pointermove", onPointerMove);
    };
  }, []);

  return <div ref={mountRef}></div>;
};

export default Background;
