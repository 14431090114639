const sectionList = {
  Experience: 0,
  Projects: 1,
  Skills: 2
};

export const BottomBar = ({ setMinimized, minimized, setShow, show }) => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClick = (item) => {
    if (sectionList[item] > 2) {
      scrollToSection(item);
      return;
    }
    if (minimized.includes(sectionList[item])) {
      scrollToSection(item);
      return;
    }
    const newShow = [...show];
    newShow.push(sectionList[item]);
    setMinimized(minimized.filter((it) => it !== item));
    setShow(newShow.sort());
    setTimeout(() => {
      scrollToSection(item);
    }, 500);
  };

  return (
    <div className="bottom-bar">
      {minimized.map((item) => (
        <div key={item} onClick={() => handleClick(item)}>
          {item}{" "}
        </div>
      ))}
    </div>
  );
};
