export const experiences = [
  {
    title: "Full-stack Developer",
    company: "medlynks",
    points: [
      "Created an app from scratch using NestJS for the back end (TypeScript) and React for the front end (JavaScript). The designs used in the app were made with Figma.",
      "Handled CI/CD using GitHub Actions on AWS. Deployed API to AWS Fargate for auto-scaling and front end to Amazon S3.",
      "Implemented various features, including but not limited to searching through 50,000+ records to give the best matches, using Google Maps with interactive marker, and implementing Socket.io for real-time chat between users."
    ]
  },
  {
    title: "Lead Software Engineer",
    company: "Streamline Studios",
    points: [
      "Worked closely with project managers, producers, and other leads. Ensured that schedules, milestones, and deliveries maintained high quality for all development projects.",
      "Architected efficient and reusable front-end systems that drove complex web applications.",
      "Mentored and guided junior engineers to help continuously upskill the team and build a strong and positive culture.",
      "Documented and demonstrated solutions by developing documentation, flowcharts, layouts, diagrams, charts, code comments, and clear code.",
      "Recruited and trained developers to grow the team in size and technical capability.",
      "Built and maintained relationships with clients and external partners through conference calls, emails, and face-to-face meetings.",
      "Identified risks that may slow down production. Worked with other managers to proactively formulate solutions."
    ]
  },
  {
    title: "Software Engineer",
    company: "Streamline Studios",
    points: [
      "Designed, developed, tested, and deployed new and existing software. Used a variety of technologies, frameworks, and platforms.",
      "Worked with the technical lead to determine user requirements. Assisted in creating specialized designs for software solutions.",
      "Produced and maintained high-quality documentation to maximize knowledge sharing for the intended audience."
    ]
  },
  {
    title: "Junior Software Engineer",
    company: "Streamline Studios",
    points: [
      "Collaborated with product designers, product managers, and software engineers. Delivered compelling user-facing products.",
      "Developed and maintained a web-based project management tool.",
      "Confronted and solved unique and unexpected engineering challenges specific to the gaming industry."
    ]
  }
];

export const workExperience = [
  {
    company: "Medlynks",
    role: "Full-stack Developer",
    link: "https://medlynks.com/",
    description: `
    As the sole developer, I successfully built a robust application from scratch using NestJS (TypeScript) for the back end and React (JavaScript) for the front end. Utilizing my expertise in these technologies, I ensured the app's performance, scalability, and user-friendliness. The app's designs, created in Figma, were translated into a high-quality user interface. I handled the continuous integration/continuous deployment (CI/CD) process using GitHub Actions on AWS, deploying the API to AWS Fargate for auto-scaling and the front end to Amazon S3. Noteworthy achievements include implementing advanced features such as a highly efficient search functionality for over 50,000 records, seamless integration of Google Maps with interactive markers, and real-time chat functionality using Socket.io. Overall, I aimed to deliver an exceptional user experience throughout the development process.`
  },
  {
    company: "The Walking Dead: Last Mile",
    role: "Lead Software Engineer",
    link:
      "https://www.genvidtech.com/example-miles/the-walking-dead-last-mile/",
    description:
      "As the lead software engineer for an innovative Facebook game, I had diverse responsibilities that involved combining interactive user engagement with a live stream experience. Built on the popular React framework, the game featured a user interaction layer that allowed active participation through game changes and mini-games. A key aspect of my role was effective communication with the client, ensuring their vision was reflected in the final product. This included understanding requirements, addressing concerns, and providing regular updates. Making critical game flow decisions and collaborating with designers and developers to iterate and refine features were vital tasks. Leveraging my React expertise and leadership skills, I aimed to create an immersive and captivating gameplay experience on Facebook."
  },
  {
    company: "Shopify Application",
    role: "Front-end React Developer",
    description:
      "Acted as a front-end React developer for a Shopify store. The store used Shopify storefront API as well as Recharge API. The project also included various React components that can be easily dragged onto the UI using Builder.io."
  },
  {
    company: "Rival Peak",
    role: "Front-end Developer",
    link: "https://www.genvidtech.com/example-miles/rival-peak/",
    description:
      "Rival Peak was an experimental competition reality show featuring artificially intelligent contestants whose fate is controlled by the viewing audience. The React-based front end allowed users to interact with the real-time experience through an instant game on Facebook.\n\nI was the front-end developer and coordinated different interactions within the application, including animations, canvas elements, and minigames. My job also included communicating with the game developers to set goals and remove obstacles as quickly as possible."
  },
  {
    company: "Interactive Web Game: Monarch",
    role: "Developer",
    link: "https://www.genvidtech.com/example-miles/project-monarch/",
    description:
      "I was one of the developers in charge of an interactive web game. The game consisted of an interactive UI involving canvas elements and various minigames. I also led the deployment of the web application."
  }
];

export const skills = [
  {
    category: "Languages",
    items: [
      "TypeScript",
      "JavaScript",
      "HTML",
      "CSS",
      "C++",
      "GraphQL",
      "HTML5"
    ]
  },
  {
    category: "Frameworks",
    items: [
      "Express.js",
      "Redux",
      "React",
      "MUI (Material UI)",
      "NestJS",
      "Bootstrap",
      "Next.js",
      "Blitz"
    ]
  },

  {
    category: "Tools",
    items: [
      "Bitbucket",
      "GitHub",
      "Figma",
      "Amazon CloudFront CDN",
      "Sequelize",
      "Jira",
      "Canvas",
      "Git"
    ]
  },
  {
    category: "Paradigms",
    items: ["Scrum", "Agile", "DevOps", "Management"]
  },
  {
    category: "Storage",
    items: ["JSON", "MySQL", "NoSQL", "Amazon S3 (AWS S3)", "SQLite", "MongoDB"]
  },
  {
    category: "Platforms",
    items: ["Amazon EC2", "Amazon Web Services (AWS)", "Linux", "MacOS"]
  },
  {
    category: "Other",
    items: [
      "Front-end",
      "Back-end",
      "User Interface (UI)",
      "Web Development",
      "LiveStream",
      "YouTube Livestreaming",
      "OAuth",
      "WebSockets",
      "Cookies",
      "Storybook",
      "SaaS",
      "Team Leadership",
      "eCommerce",
      "Maps",
      "Full-stack"
    ]
  },
  {
    category: "Libraries/APIs",
    items: [
      "React",
      "Node.js",
      "Socket.IO",
      "YouTube API",
      "Google API",
      "REST APIs",
      "Three.js",
      "Facebook API",
      "Google Sheets API",
      "Shopify API",
      "Sockets",
      "Tailwind CSS",
      "JSON Web Tokens (JWT)",
      "Jest"
    ]
  },
  {
    category: "Industry Expertise",
    items: ["Healthcare", "Web Games", "2d Games", "HIPPA Compliance"]
  }
];
