import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./index.css";
import { experiences, skills } from "./constants";
import { AnimatedList } from "./animatedList";
import WordExperienceCards from "./cards";
import { NavBar } from "./navbar";
import { BottomBar } from "./bottomBar";
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa";
import Image from "./picture.png";
import Resume from "./HammadSaeed.pdf";
import Button from "./Buttton";

const CloseIcon = ({ handleRemove, handleMinimize, index, title }) => (
  <div className="icon-container">
    <div onClick={() => handleMinimize(index, title)} className="minimize-icon">
      {" "}
      _{" "}
    </div>
    <div className="close-icon" onClick={() => handleRemove(index)}>
      X
    </div>
  </div>
);

export const Portfolio = (props) => {
  const { setShowBg, showBg } = props;
  const [show, setShow] = useState([0, 1, 2]);
  const [minimized, setMinimized] = useState([]);

  const handleRemove = (index) => {
    const newShow = show.filter((i) => i !== index);
    setShow(newShow || []);
  };

  const handleMinimize = (index, title) => {
    const newShow = show.filter((i) => i !== index);
    setShow(newShow || []);
    setMinimized([...minimized, title]);
  };

  const sections = [
    <section className="experience" id="Experience">
      <CloseIcon
        handleMinimize={handleMinimize}
        handleRemove={handleRemove}
        index={0}
        title={"Experience"}
      />
      <div className="section-content">
        <h2>Experience</h2>
        <div className="job">
          {experiences.map((exp) => (
            <div className="job">
              <h3>{exp.title}</h3>
              <h4>{exp.company}</h4>
              <AnimatedList list={exp.points} />
            </div>
          ))}
        </div>
      </div>
    </section>,
    <section className="projects" id="Projects">
      <CloseIcon
        handleMinimize={handleMinimize}
        handleRemove={handleRemove}
        index={1}
        title={"Projects"}
      />
      <div className="section-content">
        <div style={{ minWidth: "500px", maxWidth: "800px" }}>
          <h2>Projects</h2>
          <WordExperienceCards />
        </div>
      </div>
    </section>,
    <section className="experience" id="Skills">
      <CloseIcon
        handleMinimize={handleMinimize}
        handleRemove={handleRemove}
        index={2}
        title={"Skills"}
      />
      <div className="section-content">
        <h2>Skills</h2>
        <div className="skills">
          {skills.map((skill) => (
            <div className="job">
              <h3>{skill.category}</h3>
              <AnimatedList triggerOnce list={skill.items} />
            </div>
          ))}
        </div>
      </div>
    </section>,
  ];

  const handleOnClick = () => {
    window.open(Resume, "_blank");
  };

  const handleUpdateBg = () => {
    setShowBg(!showBg);
  };

  return (
    <div className="App">
      <NavBar
        show={show}
        setShow={setShow}
        setMinimized={setMinimized}
        minimized={minimized}
        handleUpdateBg={handleUpdateBg}
        showBg={showBg}
      />
      <section className="hero" id="About">
        <div className="hero-content">
          <div className="hero-info">
            <h1>Ahmed Hammad Saeed</h1>
            <p>
              Hammad is a highly qualified and reliable professional with
              comprehensive web development skills. He specializes in developing
              web applications that work with JavaScript and TypeScript using
              frameworks such as React and Node.js. Hammad is also an expert in
              creating responsive and mobile-friendly websites with excellent
              communication and teamwork skills.
            </p>
            <div className="button-container">
              <Button onClick={handleOnClick} />
            </div>
          </div>
          <div className="hero-image">
            <motion.img
              src={Image}
              alt="Ahmed Hammad Saeed"
              whileHover={{ rotateY: 10, rotateX: 10, scale: 1.35 }}
              transition={{ duration: 0.35 }}
            />
          </div>
        </div>
      </section>
      <AnimatePresence style={{ width: "100%" }}>
        {show?.length ? (
          show.map((Item, index) => {
            return (
              <motion.div
                layout
                key={`test-${index}-${sections.length}`}
                anmiate={{ scale: 1, opacity: 1 }}
                exit={{
                  scale: 0.25,
                  y: 550,
                  x: -500,
                  display: "none",
                }}
                transition={{ type: "spring", duration: 0.5 }}
                style={{ width: "80%", display: "flex" }}
              >
                {sections[Item]}
              </motion.div>
            );
          })
        ) : (
          <></>
        )}
      </AnimatePresence>
      <section className="contact" id="Contact Me">
        <div>
          <h2>Contact Me</h2>
          <div className="contact-icons">
            <a href={`mailto:hammadsaeed_1997@hotmail.com`}>
              <FaEnvelope className="icon" />
            </a>
            <a
              href="https://www.linkedin.com/in/hammad-saeed/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="icon" />
            </a>
            <a
              href="https://github.com/hammadsaeed"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub className="icon" />
            </a>
          </div>
        </div>
      </section>
      <BottomBar
        setMinimized={setMinimized}
        minimized={minimized}
        setShow={setShow}
        show={show}
      />
    </div>
  );
};
