import "./index.css";
import { motion } from "framer-motion";
import { workExperience } from "./constants";
import { useState } from "react";
import { FaLink } from "react-icons/fa";

const cardVariants = {
  offscreen: {
    y: 310,
    justifyContent: "flex-start",
  },
  onscreen: {
    y: 10,
    rotate: -10,
    justifyContent: "center",
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

function Card({ description, company, role, link }) {
  const [hover, setHover] = useState(false);
  const handleClick = (event) => {
    event.preventDefault();
    if (link) {
      window.open(link, "_blank");
    }
  };
  return (
    <motion.div
      className="card-container"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ amount: 0.8 }}
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      <svg
        className="splash"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 219.361 500 230.6"
      >
        <path
          d="m0 303.5c0-11.046 8.995-18.399 20-20l440-64c10.085-1.467 20 8.954 20 20l20 190.5c0 11.046-8.954 20-20 20l-460 0c-11.046 0-20-8.954-20-20z"
          fill="rgba(128, 128, 128, 0.25)"
        />
      </svg>
      <motion.div
        className="card"
        variants={cardVariants}
        transition={{ duration: 0.3 }}
        onClick={handleClick}
        style={{ cursor: link ? "pointer" : "default" }}
        whileHover={{
          scale: [null, 1.025, 1.025],
          border: link ? "3px solid #029ab5" : "",
        }}
      >
        <h3 style={{ margin: "6px" }}>{company}</h3>
        <p style={{ margin: 0, marginBottom: 22 }}>
          <strong>Role: </strong>
          {role}
        </p>
        {description}
        {hover && link ? <FaLink className="link-icon" /> : <></>}
      </motion.div>
    </motion.div>
  );
}

export default function WordExperienceCards() {
  return workExperience.map(({ description, company, role, link }) => (
    <Card
      description={description}
      company={company}
      link={link}
      role={role}
      key={role}
    />
  ));
}
