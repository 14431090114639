import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const sectionList = {
  Experience: 0,
  Projects: 1,
  Skills: 2,
  About: 3,
  "Contact Me": 4,
};
const menuList = ["About", "Experience", "Projects", "Skills", "Contact Me"];

function Switch({ isOn, ...props }) {
  const className = `switch ${isOn ? "on" : "off"}`;

  return (
    <motion.div animate className={className} {...props}>
      <motion.div animate />
    </motion.div>
  );
}

export const NavBar = ({
  setShow,
  show,
  setMinimized,
  minimized,
  handleUpdateBg,
  showBg,
}) => {
  const [width, setWidth] = useState(0);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClick = (item) => {
    if (sectionList[item] > 2) {
      scrollToSection(item);
      return;
    }
    if (show.includes(sectionList[item])) {
      scrollToSection(item);
      return;
    }
    const newShow = [...show];
    newShow.push(sectionList[item]);
    setMinimized(minimized.filter((it) => it !== item));
    setShow(newShow.sort());
    setTimeout(() => {
      scrollToSection(item);
    }, 500);
  };

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (
    <div className="navbar">
      <div className="navbar-text">
        {menuList.map((item) => (
          <div key={item} onClick={() => handleClick(item)}>
            {item}{" "}
          </div>
        ))}
      </div>
      {width > 650 && (
        <div className="navbar-switch">
          <div>Animate</div>
          <Switch isOn={showBg} onClick={() => handleUpdateBg()} />
        </div>
      )}
    </div>
  );
};
