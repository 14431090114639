import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const AnimatedList = ({ list, triggerOnce = false }) => {
  const { ref, inView } = useInView({
    triggerOnce: triggerOnce,
    threshold: 0.2,
    type: "tween"
  });

  const listItemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };
  return (
    <ul className="list" ref={ref}>
      {list.map((point, i) => (
        <motion.li
          key={`point-${i}`}
          className="list-item"
          variants={listItemVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          transition={{ delay: 0.2 * i }}
        >
          {point}
        </motion.li>
      ))}
    </ul>
  );
};
